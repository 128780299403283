import axios from 'axios';
const baseUrl = 'https://api.alganoon.com/api/v1/';
const request = axios.create({
  baseURL: baseUrl,
  headers: {
    'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'multipart/form-data',
  },
  //credentials: "same-origin",
  //withCredentials: true,
});

request.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token')
    //const token='eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZGIwZDgzNDc1NmVmNDE4NjUzZGM4ZjVlMzZiOTA4OWMzNWQ1MWMxMTk5Yzc4YjZkMmQxY2Q3ZjMzNjMxMWI2NGZlMzU1YWI3MjhmMTgwNTIiLCJpYXQiOjE2MDUwMDE2NzAsIm5iZiI6MTYwNTAwMTY3MCwiZXhwIjoxNjM2NTM3NjY5LCJzdWIiOiIzIiwic2NvcGVzIjpbXX0.RpUEk3zTkhF-ClbgDPwfqhN-gs48cTwrbbZGN9BRRQiwWwObnX6FhF8yorir1Z8SV5PyX3JxPEO8CeaMyts1rCOhZ__cdN3OFKqo7j1YQTkU3Pjhix6EKF0QuxDjcQjtpYULzNmJ5ut263Q6HmI453NJj42pxRNI6GP-__qfj_K-Byd8A8A2MoNAG5M8fUTdNZUa28YKpBnq0ikLbGEVJbZ14AFuvtDVez7dNJLlg5P_uxlnmaZMcjTZEtyqX06HGECT-lOi74p0AapYn4cC86K9DA4Vb2jcUwridlyjHw-6aPYv9AmQalWZPdFMHlDjwIIG5mHWVvYJewiG9jFjs1BDJEcQUxmiPonGxI7cRNhnrgVHQrQvhC-LYrmkM46GfvboCHBenqvepPdd0f4FucmUTnU9-khA1VIQhd9gHjkrK3buMePqaMPPfzVt9aE_3I1KsqE2ZBbUJnlDCmbClPH1r-_MrSg5hczJvfk8L9txLRqe_UqmODpeDSORHP2GG1s_Qf_hpGwX-9Oop0JcE3q3CU6499gyOlQ8sKHarQxfyQ34ADs0iaP78zCkjR1tYN8Fl9M8hh1CL4_aXkUuu6mz-_c4E36L8lcV8IzP_bGIQd2bDOmqG3RWeySagWnomubfu32wuVWvUQkw-SwjqmkIq_PWWwM2PvEEOh3r444';
    console.log("config..", config);
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config
  },
  function (error) {
    console.log("Error..", error);
    return Promise.reject(error)
  }
)


export const Get = async (route) => {
  try {
    const response = await request.get(route);
    return response.data;
  } catch (error) {
    return error.response ? error.response.data : error;
  }
};

export const Post = async (route, data) => {
  try {
    const response = await request.post(route, data);
    return response.data;
  } catch (error) {
    return error.response ? error.response.data : error;
  }
};

export const Put = async (route, data) => {
  try {
    const response = await request.put(route, data);
    return response.data;
  } catch (error) {
    return error.response ? error.response.data : error;
  }
};

export const Delete = async (route, data) => {
  try {
    const response = await request.delete(route, data);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
