import React from 'react';

//if needed give it absolute position via css and raise z index
const Loader = ({ message1, message2, message3 }) => {
  const message1_en = "";
  const message2_en = "";
  const message3_en = "..Loading services";

  const message1_ar = "المنصة القانونية alganoon.com تقدم من خلال الوسطاء خدمات واستشارات قانونية لنخبة من المحامين ";
  const message2_ar = "والمستشارين المتخصصين في جميع الخدمات العدلية والقانونية للأفراد والشركات والجهات الحكومية، فقط ";
  const message3_ar = "اطلب الخدمة التي تحتاجها وسوف يقوم فريقنا بالتواصل معك عاجلا لتقديم الخدمة المرضية";

  const message1_fr = "";
  const message2_fr = "";
  const message3_fr = "...Chargement des services en cours";

  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      textAlign: 'center',
      padding: '10px',
      flexDirection: 'column'
    }}>
      <div><img src={require(`./assets/images/headerLogo1.png`)} alt="" className={"deviceImage img-fluid"} /></div>
      {message1_ar}
      <p> {message2_ar}</p>
      <p> {message3_ar}</p>
      <br></br>
      <br></br>

      <p> {message1_en}</p>
      <p> {message2_en}</p>
      <p> {message3_en}</p>
      <br></br>
      <br></br>
      <p> {message1_fr}</p>
      <p> {message2_fr}</p>
      <p> {message3_fr}</p>
    </div>
  )
}

export default Loader;