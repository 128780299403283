import React, { Component } from "react";
import {
    Container, Row, Col, Label, Input, Button, InputGroup, FormGroup, FormFeedback, Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";
import Select from "react-select";
import sideImage from "../../../assets/images/Group 426.png"
import classnames from "classnames";
import { Formik } from "formik";
import * as Yup from "yup";
import { Redirect } from "react-router";
import Footer from "../../Components/Footer";
import GoogleMap from "../../Components/GoogleMap";
import ToastBox from "../../Components/ToastBox/ToastBox";
import { Post, Get } from "../../../utilities/axiosRequest";
import axios from 'axios';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
const phoneRegExp = /^(9665|\+9665|5|05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, "Enter valid name")
        .required(i18next.lang == "en" ? "Name is required" : "الإسم مطلوب"),

    mobileNumber: Yup.string()
        .required(i18next.lang == "en" ? "Mobile number is required" : "رقم الجوال مطلوب")
        .matches(phoneRegExp, i18next.lang == "en" ? 'Phone number is not valid' : ' رقم الجوال خطأ'),

    emailAddress: Yup.string()
        .email(i18next.lang == "en" ? "Please enter valid e-mail" : "البريد الآلي مطلوب")
        .required(i18next.lang == "en" ? "E-mail is required" : "البريد الآلي مطلوب"),
    password: Yup.string()
        .trim("No white space allow in starting")
        .required(i18next.lang == "en" ? "Password is required" : "كلمة المرور مطلوبة")
        .min(8, 'Seems a bit short...')
        .max(14, 'We prefer insecure system, try a shorter password.')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),


    confirmPass: Yup.string()
        .trim("No white space allow in starting")
        .required(i18next.lang == "en" ? "Confirm Password is required" : "تأكيد كلمة المرور مطلوبة")
        .test('passwords-match', i18next.lang == "en" ? 'Passwords must match' : 'كلمة السر غير مطابقة', function (value) {
            return this.parent.password === value;
        }),
    username: Yup.string()
        .min(2, 'Too Short!')
        .max(14, 'Too Long!')
        .required(i18next.lang == "en" ? 'Username is Required' : 'اسم المستخدم مطلوب'),
});
const country = [
    {
        value: "India",
        label: "India"
    }
];
const city = [
    { value: "Ahmedabad", label: "Ahmedabad" },
    { value: "Rajkot", label: "Rajkot" },
    { value: "Surat", label: "Surat" },
];

class SignUp extends Component {



    state = {
        showPassword: false,
        country: [],
        city: [],
        lat: '21.4249',
        lng: '39.850',
        submitvalues: [],
        spinnerLoading: false,
        show: false,
        userDuplicate: false,
        userDuplicateEmail: false,
        country_code: '966',
        country_id: 1,
        city_id: 1,
        isConfirmOTPModal: false,
        otpVerify: '',
        userId: '',

    }

    setShow = (status) => {
        this.setState({ show: status });

    }
    loginHandler = (values) => {
        const { cookies } = this.props;

        if (this.state.userDuplicateEmail) {

            this.setState({ message: 'بريد هذا العميل مسجل من قبل', show: true })
            return false;

        } else if (this.state.userDuplicate) {

            this.setState({ message: 'اسم المستخدم لهذا العميل مسجل من قبل', show: true })
            return false;
        }

        const data = {
            mobileNumber: values.mobileNumber,
            user_type: 1
        };
        const options = {
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        };


        /***  axios.post('https://api.alganoon.com/api/v1/send-otp', data, options)
   
           .then(result => {
             
                  if(result.data.result=="exist"){
   
                   this.setState({message:'لايمكن التسجيل .رقم جوال هذا العميل مسجل من قبل' ,show:true})
                   this.setState({ isConfirmOTPModal: false });
                  }else{
   
                   this.setState({ isConfirmOTPModal: true });
                  }
                   console.log("Result...",result)
           })*/


        console.log("sign up", values.name);
        values.lat = this.state.lat;
        values.lng = this.state.lng;
        this.gettingData(values);
        //  return false;
        axios.post('https://api.alganoon.com/api/v1/user/signup', values, { headers: { 'Access-Control-Allow-Origin': '*' } })
            .then(result => {

                if (result.data.messsage != "") {
                    console.log("customer repos....", result.data.customer)
                    this.setState({ message: 'تم تسجيل حسابك بنجاح', show: true })

                    cookies.set("customer", result.data.customer, { path: "/", expires: new Date(Date.now() + 2592000) });

                    cookies.set("user_type_id", result.data.user_type_id, { path: "/", expires: new Date(Date.now() + 2592000) });

                } else {
                    this.setState({ message: result.error, show: true })
                }
                if (result.data.token != undefined)
                    localStorage.setItem("token", result.data.token)
                // window.location.reload(true);
            });

        /** Post("ontimefix.com/backend/api/v1/countries",values).then(result => { result.countries.forEach(function(data, idx) {
              countries.push({'value':data.country_id, 'label':data.country_name});   
              });**/
        // localStorage.setItem("token", "cjkacnjdajbhjabfhknsnqwopodkmcnmnzx,clkoakdlMDKNDMNCKJNKJASNCABSDKJADNjabdkj");
        // this.props.history.replace("/");
    }

    gettingData = (values) => {
        console.log(values)
        this.setState(prev => ({
            ...prev,
            ...values
        }));
    };
    verifyOTP = () => {
        const { cookies } = this.props;
        console.log("Result state signup", this.state);
        this.setState({ isConfirmOTPModal: false });
        axios.get(`https://api.alganoon.com/api/v1/otp-verify?otp_code=${this.state.otpVerify}`).then(result => {
            console.log("result...", result);
            if (!result.data.otpresult) {
                axios.post('https://api.alganoon.com/api/v1/user/signup', this.state, { headers: { 'Access-Control-Allow-Origin': '*' } })
                    .then(result => {
                        console.log("respons....", result.data.message)
                        if (result.data.messsage != "") {
                            this.setState({ message: result.data.message, show: true })
                            cookies.set("customer", result.data.customer, { path: "/", expires: new Date(Date.now() + 2592000) });
                            localStorage.setItem("firstname", result.data.firstname)
                            cookies.set("user_type_id", result.data.user_type_id, { path: "/", expires: new Date(Date.now() + 2592000) });
                        } else {
                            this.setState({ message: result.error, show: true })
                        }
                        if (result.data.token != undefined)
                            localStorage.setItem("token", result.data.token)
                        // window.location.reload(true);
                    });

            } else {

                this.setState({ message: "Invalid OTP", show: true })
            }

        });

    }

    validateUsername = (username) => {

        axios.get(`https://api.alganoon.com/api/v1/user/duplicate-user?username=${username}`).then(result => {

            if (result.data.duplicate) {
                this.setState({ userDuplicate: true })

            } else {

                this.setState({ userDuplicate: false })
            }


        }


        );
    }

    validateEmail = (email) => {

        axios.get(`https://api.alganoon.com/api/v1/user/duplicate-email?email=${email}`).then(result => {

            if (result.data.duplicate) {
                this.setState({ userDuplicateEmail: true })

            } else {

                this.setState({ userDuplicateEmail: false })
            }


        }


        );
    }
    forgotPassword = () => {
        this.props.history.push("/login")
    }

    updateLoctaionCord = (lat, long) => {
        console.log("Latitude..", lat);
        this.setState({ lat: lat, lng: long })
    }



    changearabicToEng = (str) => {
        const persianNumbers = ["۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹", "۰"]
        const arabicNumbers = ["١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩", "٠"]
        const englishNumbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]

        str = str.split("").map(c => englishNumbers[persianNumbers.indexOf(c)] ||
            englishNumbers[arabicNumbers.indexOf(c)] || c).join("")
        this.setState({ mobileNumber: str })
        return str;
    };

    changeArabic = (event, setFieldValue) => {
        console.log("event", event)
        var number = this.changearabicToEng(event.target.value);
        console.log("afetr conversion", number)
        setFieldValue('mobileNumber', number)
    }

    filterCities = (selected, setFieldValue) => {
        const cities = [];
        setFieldValue('country_id', selected);
        this.setState({ country_id: selected });

        Get(`https://api.alganoon.com/api/v1/country-info?&country_id=${selected}`).then(result => {

            this.setState({ country_code: result.countryinfo[0].country_code, support_number: result.countryinfo[0].support_number })
        }
        );

        Get(`https://api.alganoon.com/api/v1/cities?&country_id=${selected}`).then(result => {
            result.cities.forEach(function (data, idx) {
                cities.push({ 'value': data.city_id, 'label': i18next.language == "en" ? data.city_name : data.city_name_ar });
            });
            this.setState({ city: cities })
        }
        );
        // Get(`https://api.alganoon.com/api/v1/services?&city_id=${selected}`).then(result => this.setState({services: result.cityservices }));  

    }

    componentDidMount() {


        const countries = [];
        const cities = [];
        Get("https://api.alganoon.com/api/v1/countries").then(result => {
            result.countries.forEach(function (data, idx) {
                countries.push({ 'value': data.country_id, 'label': data.country_name });
            });

            this.setState({ country: countries });
        });
        Get("https://api.alganoon.com/api/v1/cities").then(result => {
            result.cities.forEach(function (data, idx) {
                cities.push({ 'value': data.city_id, 'label': data.city_name });
            });
            this.setState({ city: cities })
        }
        );

    };

    handleChangeText = e => {
        console.log(e);
        this.setState({ otpVerify: e.target.value });
        //this.props.onChange(e);
    };

    render() {
        const { t } = this.props;
        const { cookies } = this.props;
        const userId = this.props.cookies.get("customer");
        const { showPassword, country, city, lat, lng, message, show, spinnerLoading, userDuplicate, country_code, isConfirmOTPModal, userDuplicateEmail } = this.state;

        {/*if (localStorage.getItem("token")) return <Redirect to="/" from="*" />*/ }
        return (
            <>
                <Modal isOpen={isConfirmOTPModal} backdrop="static">
                    <ModalHeader toggle={() => this.setState({ isConfirmOTPModal: false })}>OTP Verification</ModalHeader>
                    <ModalBody >
                        <Row>

                            <Col xs="12" className="mb-4 d-flex">
                                <Label>{t('Please verify OTP')}</Label>
                                <FormGroup>
                                    <Input

                                        type="text"
                                        value={this.state.otpVerify}
                                        name="otpVerify"
                                        onChange={this.handleChangeText}
                                        onBlur=""
                                        placeholder={t('otpVerify')}
                                        className="BoxShadow pl-4" />

                                </FormGroup>
                            </Col>
                            <Col xs="12" className="text-center mt-1">
                                <Button className="RequestBtn px-4 font-weight-bolder" onClick={this.verifyOTP} >{t('Send')}</Button>
                            </Col>

                        </Row>
                    </ModalBody>
                </Modal>

                <ToastBox show={show} message={message} setShow={this.setShow} />
                <Container>
                    <Row>
                        <Col md="6" className="align-self-center mt-3">
                            <h3 style={{ color: "#FF5900" }} className={i18next.language == "ar" ? 'text-right pr-1' : ''}>{t('Roving Maintenance')}</h3>
                            <Formik
                                initialValues={{
                                    name: "",
                                    mobileNumber: "",
                                    emailAddress: "",
                                    password: "",
                                    confirmPass: "",
                                    lat: "",
                                    lng: "",
                                    country_id: 1,
                                    city_id: 10,
                                    username: "",
                                    userId: userId ? userId : ''
                                }}
                                validationSchema={validationSchema}
                                onSubmit={

                                    values => (this.loginHandler(values))

                                }>
                                {({
                                    errors,
                                    values,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    setFieldValue
                                }) => (
                                    <form>
                                        <Row className="mt-1 mb-5">
                                            <Col xs="12" className="">

                                                <FormGroup>
                                                    <Input
                                                        invalid={errors.name && touched.name}
                                                        type="text"
                                                        value={values.name}
                                                        name="name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder={t('Full Name')}
                                                        maxlength="30"
                                                        className="BoxShadow pl-4" />
                                                    <FormFeedback>{touched.name && errors.name}</FormFeedback>
                                                </FormGroup>
                                            </Col>

                                            <Col xs="12">
                                                <Row>
                                                    <Col md="12" lg="6">
                                                        <FormGroup>
                                                            <Select
                                                                invalid={errors.country && touched.country}
                                                                className={classnames("BoxShadow mb-2", { 'select-element is-invalid': touched.country_id && errors.country_id })}
                                                                value={country.find(p => p.value === values.country_id) || null}
                                                                onChange={(value) => this.filterCities(value.value, setFieldValue)}

                                                                //onChange={(value) =>   setFieldValue("country_id", value.value)}
                                                                placeholder={t('signup')}
                                                                onBlur={handleBlur("country")}
                                                                name="country_id"
                                                                options={country} />
                                                            <FormFeedback> {touched.country_id && errors.country_id}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="12" lg="6">
                                                        <FormGroup>
                                                            <div className="d-flex flex-wrap mb-2">
                                                                <Select
                                                                    className={classnames("BoxShadow flex-grow-1", { 'select-element is-invalid': touched.city_id && errors.city_id })}
                                                                    value={city.find(p => p.value === values.city_id) || null}

                                                                    onChange={(value) => setFieldValue("city_id", value.value)}
                                                                    placeholder="Select City..."
                                                                    onBlur={handleBlur("city")}
                                                                    name="city_id"
                                                                    options={city} />
                                                                <FormFeedback className="w-100">{touched.city_id && errors.city_id}</FormFeedback>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>




                                            <Col xs="12" className="">
                                                {/**  <Label className={i18next.language=="ar" ? 'text-right pr-1' : '' } >{t('Mobile Number')}</Label>
                                                    <FormGroup>
                                                        <Input
                                                            invalid={errors.mobileNumber && touched.mobileNumber}
                                                            type="text"
                                                            value={values.mobileNumber}
                                                            name="mobileNumber"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder={t('Mobile Number')}
                                                            maxlength="14"

                                                            className="BoxShadow pl-4" />
                                                        <FormFeedback>{touched.mobileNumber && errors.mobileNumber}</FormFeedback>
                                                    </FormGroup>**/}
                                                <Row>
                                                    <Col xs="9" md="9" lg="9">
                                                        <div className="d-flex flex-wrap mt-2">
                                                            <FormGroup>
                                                                <Input
                                                                    invalid={errors.mobileNumber && touched.mobileNumber}
                                                                    placeholder={t('Mobile No')}
                                                                    value={values.mobileNumber}
                                                                    onChange={(value) => setFieldValue("mobileNumber", value.value)}
                                                                    onChange={ev => this.changeArabic(ev, setFieldValue)}
                                                                    name="mobileNumber"
                                                                    type="text"
                                                                    maxlength="10"

                                                                    onBlur={handleBlur("mobileNumber")}
                                                                    className="BoxShadow mb-2 d-block" />
                                                                <FormFeedback>{touched.mobileNumber && errors.mobileNumber}</FormFeedback>
                                                            </FormGroup>
                                                        </div>
                                                    </Col>
                                                    <Col xs="3" md="3" lg="3" className="d-flex pt-2 ">
                                                        <span className="country-code">{country_code}+</span>
                                                    </Col>

                                                </Row>
                                            </Col>
                                            <Col xs="12" >
                                                <Row>
                                                    <Col md="6" sm="12">

                                                        <FormGroup>
                                                            <InputGroup className={classnames("BoxShadow", { "select-element is-invalid": errors.username && touched.username || userDuplicateEmail })}>
                                                                <Input
                                                                    invalid={errors.emailAddress && touched.emailAddress}
                                                                    type="text"
                                                                    value={values.emailAddress}
                                                                    name="emailAddress"
                                                                    onChange={handleChange("emailAddress")}
                                                                    onBlur={e => {
                                                                        handleBlur(e);
                                                                        this.validateEmail(e.target.value)
                                                                    }}
                                                                    placeholder={t('Email Address')}
                                                                    maxlength="75"
                                                                    className="BoxShadow pl-4" />
                                                            </InputGroup>
                                                            <FormFeedback>{touched.emailAddress && errors.emailAddress}</FormFeedback>
                                                            {userDuplicateEmail &&
                                                                <FormFeedback>{t('Email already taken')}</FormFeedback>
                                                            }

                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="12" md="6">

                                                        <FormGroup>
                                                            <InputGroup className={classnames("BoxShadow", { "select-element is-invalid": errors.username && touched.username || userDuplicate })}>
                                                                <Input
                                                                    invalid={errors.username && touched.username}
                                                                    autoComplete="off"
                                                                    type="text"
                                                                    value={values.username}
                                                                    name="username"
                                                                    onChange={handleChange}
                                                                    onBlur={e => {
                                                                        handleBlur(e);
                                                                        this.validateUsername(e.target.value)
                                                                    }}

                                                                    placeholder={t('Username')}
                                                                    maxlength="13"
                                                                    className="pl-4 border-0 shadow-none" />

                                                            </InputGroup>
                                                            <FormFeedback>{touched.username && errors.username}</FormFeedback>
                                                            {userDuplicate &&
                                                                <FormFeedback>{t('Username already taken')}</FormFeedback>
                                                            }
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col xs="12" >
                                                <Row>
                                                    <Col md="6" sm="12">

                                                        <FormGroup>
                                                            <InputGroup className={classnames("BoxShadow", { "select-element is-invalid": errors.password && touched.password })}>
                                                                <Input
                                                                    invalid={errors.password && touched.password}
                                                                    autoComplete="off"
                                                                    type={showPassword ? "text" : "password"}
                                                                    value={values.password.trim()}
                                                                    name="password"
                                                                    onChange={handleChange("password")}
                                                                    onBlur={handleBlur("password")}
                                                                    placeholder={t('Password')}
                                                                    maxlength="13"
                                                                    className="pl-4 border-0 shadow-none" />
                                                                <i
                                                                    className={classnames("passwordHideShow d-flex align-items-center pr-4", { "fa fa-eye-slash": !showPassword, "fa fa-eye": showPassword })}
                                                                    onClick={() => this.setState((prev) => ({ showPassword: !prev.showPassword }))}></i>
                                                            </InputGroup>
                                                            <FormFeedback>{touched.password && errors.password}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="12" md="6">

                                                        <FormGroup>
                                                            <InputGroup className={classnames("BoxShadow", { "select-element is-invalid": errors.confirmPass && touched.confirmPass })}>
                                                                <Input
                                                                    invalid={errors.confirmPass && touched.confirmPass}
                                                                    autoComplete="off"
                                                                    type={showPassword ? "text" : "password"}
                                                                    value={values.confirmPass.trim()}
                                                                    name="confirmPass"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    placeholder={t('Confirm Password')}
                                                                    maxlength="13"
                                                                    className="pl-4 border-0 shadow-none" />
                                                                <i
                                                                    className={classnames("passwordHideShow d-flex align-items-center pr-4", { "fa fa-eye-slash": !showPassword, "fa fa-eye": showPassword })}
                                                                    onClick={() => this.setState((prev) => ({ showPassword: !prev.showPassword }))}></i>
                                                            </InputGroup>
                                                            <FormFeedback>{touched.confirmPass && errors.confirmPass}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <GoogleMap updateLoctaionCord={this.updateLoctaionCord} defaultLat={lat} defaultLang={lng} />
                                            <Input

                                                type="hidden"
                                                value={values.userId}
                                                name="userId"
                                                onChange={handleChange("userId")}


                                                maxlength="75"
                                                className="BoxShadow pl-4" />

                                            <Col xs="12" className="text-center mt-3 mb-3">
                                                <Button className="signupBtn px-4 font-weight-bolder" onClick={handleSubmit}  >{t('Signup')}</Button>
                                            </Col>
                                        </Row>
                                    </form>
                                )}
                            </Formik>
                        </Col>
                        <Col md="6" className="d-none d-md-block pt-5 mt-5">
                            <img src={sideImage} alt="" className="img-fluid mt-5 pt-5" />
                        </Col>

                    </Row>
                </Container>
                <Footer />
            </>
        );
    }
};
export default withTranslation()(withCookies(SignUp));

