import React, { Component } from "react";
import { Container, Row, Col, Input, Button, Label, FormGroup, FormFeedback } from "reactstrap";
import CustomCheckBoxLabel from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabel";
import CustomCheckBoxLabelPlain from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabelPlain";
import sideImage from "../../../assets/images/Group 426.png";
import { Formik } from "formik";
import * as Yup from "yup";
import { Redirect } from "react-router";
import classnames from "classnames";
import Footer from "../../Components/Footer";
import { Post, Get } from "../../../utilities/axiosRequest";
import i18next from 'i18next';
import JoinPage2 from "./JoinPage2";
import { withTranslation } from 'react-i18next';
const phoneRegExp = /^(9665|\+9665|5|05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/
const validationSchema = Yup.object().shape({
    /** name: Yup.string()
         .min(5, "Enter more than 5 characters")
         .max(25, "Allow only 25 characters")
         .required("Name is required"),
 
 
      mobileNumber: Yup.string()
          .required("Mobile number is required")
         .matches(phoneRegExp, 'Phone number is not valid'),
     sponserMobileNumber:Yup.string()
        .required("Mobile number is required")
         .matches(phoneRegExp, 'Phone number is not valid'),
     sponserName: Yup.string()
         .min(3, "Enter more than 3 characters")
         .max(25, "Allow only 25 characters")
         .required("Sponser Name is required"),
     explanation: Yup.string()
         .min(5, "Enter more than 5 characters")
         .max(150, "Allow 150 characters only")
         .required("Explanation is required"),
         **/

});





class DriverProfile extends Component {

    state = {
        message: "",
        information: {
            // workType: "Work",
            //  subWorkType: "Company",
            // name: "",
            // mobileNumber: "",
            service: ["Mobile"],
            explanation: "",
            // sponserName: "",
            //sponserMobileNumber: "",

            // gender: "Male",
            // country: "",
            // nationality: "",
            // contactType: "Mobile"
        },
        isShow: "joinPage",
        country: [],
        city: [],
        services: [],
        selectedservices: [],
        show: false,


    }

    constructor(props) {
        super(props);


        this.gettingData = this.gettingData.bind(this);

    }
    setShow = (status) => {
        this.setState({ show: status });

    }
    submitHandler = (values, resetForm) => {

        this.setState(prev => ({
            // ...prev,
            // information: values,
            message: "Sent Successfully,We will contact you soon"
        }));
        resetForm({});
    }

    checkBoxChangeHandler = ({ target: { value, name } }) => {
        this.setState({ [name]: value });
    }

    gettingData = (values) => {
        console.log(values)
        this.setState(prev => ({
            ...prev,
            ...values
        }));
    };



    updateLoctaionCord = (lat, lng) => {
        this.setState({ lat: lat, lng: lng });


    }
    changeComponent = (componentName) => {
        console.log("Change component..", componentName);
        this.setState({ isShow: componentName });
    }



    componentDidMount() {
        console.log("On paeeee")
        const customer_id = localStorage.getItem("customer_id");
        this.setState({ customer_id: customer_id });
        const countries = [];
        const cities = [];

        Get("https://api.alganoon.com/api/v1/countries").then(result => {
            result.countries.forEach(function (data, idx) {
                countries.push({ 'value': data.country_id, 'label': data.country_name });
            });

            this.setState({ country: countries });
        });
        Get("https://api.alganoon.com/api/v1/cities").then(result => {
            result.cities.forEach(function (data, idx) {
                cities.push({ 'value': data.city_id, 'label': data.city_name });
            });
            this.setState({ city: cities })
        }
        );

        const default_city = 1;
        Get(`https://api.alganoon.com/api/v1/services?&city_id=${default_city}`).then(result => this.setState({ services: result.cityservices }));

        Get(`https://api.alganoon.com/api/v1/driver/driver-info`).then(result => {
            if (result.result == "success") {

                this.gettingData(result.driverinfo[0]);
                const selServices = [];

                this.setState({ loc_longittude: result.driverinfo[0].loc_longitude != undefined ? result.driverinfo[0].loc_longitude : null })
            } else {
                this.props.history("/home");
            }
        }
        );





    }
    submitHandler = (values, resetForm) => {

        // this.gettingData(values);
        this.changeComponent("joinPage2");

    };

    updateCompany = (values) => {

        Post(`https://api.alganoon.com/api/v1/user/update-store`, this.state).then((result) => {
            console.log("Result...", result.message)
            this.setState({ message: result.message })

        }
        );

    }
    updateDriver = (values) => {

        Post(`https://api.alganoon.com/api/v1/driver/update-driver`, this.state).then((result) => {
            console.log("Result...", result.message)
            this.setState({ message: result.message, show: true })

        }
        );

    }

    render() {
        const { t } = this.props;
        const token = localStorage.getItem("token");
        const { deviceType, isShow, serviceType, message, services, country, city, sponsor_name, mobile_no, company_or_individual, person_name, company_or_driver, selectedservices, sponsor_mobile, driver_name, description, show } = this.state;


        if (localStorage.getItem("token") == "") return <Redirect to="/home" from="*" />

        console.log("States..sponsor_name", this.state);
        return (
            <>

                <Container>
                    <Row className="my-5 mb-8">

                        {isShow === "joinPage2" && <JoinPage2
                            changeComponent={this.changeComponent}
                            country={country}
                            city={city}
                            updateCompany={this.updateCompany}
                            updateDriver={this.updateDriver}
                            gettingData={this.gettingData}
                            updateLoctaionCord={this.updateLoctaionCord}
                            checkBoxChangeHandler={this.checkBoxChangeHandler}
                            message={message}
                            show={show}
                            setShow={this.setShow}
                            formData={this.state}
                            selected={deviceType} />}

                        {isShow === "joinPage" &&
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    workType: company_or_driver != undefined ? company_or_driver == 1 ? 'Work' : 'Driver' : '',
                                    driver_name: driver_name != undefined ? driver_name : "",
                                    subWorkType: company_or_individual != undefined ? company_or_individual == 1 ? 'Company' : 'فرد' : '',
                                    subWorkType: 'Individual',
                                    service: selectedservices != undefined ? selectedservices : [],
                                    sponserMobileNumber: sponsor_mobile != undefined ? sponsor_mobile : '',
                                    mobileNumber: this.state.driver_mobile,
                                    sponserName: sponsor_name != undefined ? sponsor_name : "",

                                }}
                                validationSchema={validationSchema}
                                onSubmit={
                                    (values, { resetForm }) => this.submitHandler(values, resetForm)
                                }>
                                {({
                                    errors,
                                    values,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    setFieldValue,
                                    handleSubmit,
                                    enableReinitialize
                                }) => (
                                    <>
                                        <Col md="6" className="mb-3">
                                            <Row>

                                                <Col sm="12" className="mb-3">
                                                    <FormGroup>
                                                        <Input
                                                            invalid={errors.name && touched.name}
                                                            placeholder={t('Full Name')}
                                                            value={values.driver_name}
                                                            onChange={handleChange}
                                                            name="name"
                                                            type="text"
                                                            onBlur={handleBlur}
                                                            className="BoxShadow" />
                                                        <FormFeedback>{touched.name && errors.name}</FormFeedback>
                                                    </FormGroup>
                                                </Col>

                                                <Col sm="12" className="mb-3">
                                                    <FormGroup>
                                                        <Input
                                                            invalid={errors.mobileNumber && touched.mobileNumber}
                                                            placeholder={t('Mobile Number')}
                                                            value={values.mobileNumber}
                                                            onChange={handleChange}
                                                            name="mobileNumber"
                                                            type="text"
                                                            onBlur={handleBlur}
                                                            className="BoxShadow" />
                                                        <FormFeedback>{touched.mobileNumber && errors.mobileNumber}</FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="12" className="mb-3">
                                                    <FormGroup>
                                                        <Input
                                                            invalid={errors.sponserName && touched.sponserName}
                                                            placeholder={t('Sponser Name')}
                                                            value={values.sponserName}
                                                            onChange={handleChange}
                                                            name="sponserName"
                                                            type="text"
                                                            onBlur={handleBlur}
                                                            className="BoxShadow" />
                                                        <FormFeedback>{touched.sponserName && errors.sponserName}</FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="12" className="mb-3">
                                                    <FormGroup>
                                                        <Input
                                                            invalid={errors.sponserMobileNumber && touched.sponserMobileNumber}
                                                            placeholder={t('Mobile Number')}
                                                            value={values.sponserMobileNumber}
                                                            onChange={handleChange}
                                                            name="sponserMobileNumber"
                                                            type="text"
                                                            onBlur={handleBlur}
                                                            className="BoxShadow" />
                                                        <FormFeedback>{touched.sponserMobileNumber && errors.sponserMobileNumber}</FormFeedback>
                                                    </FormGroup>
                                                </Col>

                                                <Col sm="12" className="mb-3">
                                                    <FormGroup>
                                                        <Input
                                                            invalid={errors.explanation && touched.explanation}
                                                            placeholder={t('Explain')}
                                                            value={values.description}
                                                            onChange={handleChange}
                                                            name="explanation"
                                                            type="textarea"
                                                            onBlur={handleBlur}
                                                            className="BoxShadow" />
                                                        <FormFeedback>{touched.explanation && errors.explanation}</FormFeedback>
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                        </Col>
                                        <Col md="6" className="d-none d-md-block mb-3 align-self-center">
                                            <img src={sideImage} alt="" className="img-fluid" />
                                        </Col>
                                        <Col sm="12" className="text-center">
                                            <Button
                                                className="RequestBtn px-4 mb-2 font-weight-bolder"
                                                onClick={handleSubmit}>
                                                {t('Next')}
                                            </Button>

                                        </Col>
                                    </>
                                )
                                }
                            </Formik>

                        }
                    </Row>
                </Container>
                <Footer />
            </>
        );
    }
}
export default withTranslation()(DriverProfile);
